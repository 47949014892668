<template>
  <div class="wrap">
    <!--头部-->
    <Header current="aboutUs"></Header>

    <!--内容部分-->
    <div class="pkcont">
      <div class="container-fixed pkconts">
        <div class="o-m">
          <div class="ul-o">
            <ul>
              <li class="li-o "><router-link to="/aboutUs">关于我们</router-link></li>
              <li class="li-o sk_clic"><router-link to="/contactUs">联系我们</router-link></li>
            </ul>
          </div>
          <div class="ul-t sk_ul">
            <li style="display: list-item">
              <div class="lxwm">
                <p class="lianxs">联系我们</p>
                <p class="lianxiw">CONTSCT US</p>
                <div class="map">
                  <div class="maps">
                    <img src="@/assets/Image/ditu.png" alt="">
                  </div>
                  <div class="maplieb">
                    <div class="womensed">
                      <div class="chdes"></div>
                      <div class="xuanzs">
                        <ul>
                          <li>上海</li>
                          <li>广州</li>
                          <li>深圳</li>
                          <li>福州</li>
                          <li>厦门</li>
                          <li>宁波</li>
                        </ul>
                      </div>
                    </div>
                    <p class="xianxia">线下门店<span>8</span>个结果</p>
                    <div class="dizlb">
                      <div class="li1">
                        <p class="gsdzs">公司总部地址</p>
                        <div class="xiangxi">
                          <p>地址：深圳市龙华区民治街道民乐大厦439室</p>
                          <p>联系人：龙先生</p>
                          <p>电话：0755-21019642</p>
                        </div>
                        <div class="xiangxi">
                          <p>广州仓</p>
                          <p>地址：广州市白云区鹤边村通达创意园西区11栋115号仓</p>
                          <p>联系人：吴先生</p>
                          <p>电话：15989621443</p>
                        </div>
                        <div class="xiangxi">
                          <p>坂田仓</p>
                          <p>地址：深圳市龙岗区坂田街道长坑四区布龙路568-3号千禧大厦A栋</p>
                          <p>联系人：吴先生</p>
                          <p>电话：18312372921</p>
                        </div>
                        <div class="xiangxi">
                          <p>深圳机场</p>
                          <p>地址：深圳市宝安区福永街道怀德南路第二工业区领航物流园9栋8-9号</p>
                          <p>联系人：胡小姐</p>
                          <p>电话：18123875600</p>
                        </div>
                        <div class="xiangxi">
                          <p>厦门仓</p>
                          <p>地址：厦门市湖里区殿前街道长治路36号五洲汇文创园1011号</p>
                          <p>联系人：李先生</p>
                          <p>电话：18850591601</p>
                        </div>
                        <div class="xiangxi">
                          <p>义乌仓</p>
                          <p>地址：浙江省义乌市城西四海大道普洛斯（义乌）物流园A3-4号</p>
                          <p>联系人：王先生</p>
                          <p>电话：18402953869</p>
                        </div>
                        <div class="xiangxi">
                          <p>上海仓</p>
                          <p>待上线</p>
                        </div>
                        <div class="xiangxi">
                          <p>宁波仓</p>
                          <p>待上线</p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </li>

          </div>
          <div class="clear"></div>
        </div>
        <div id="pagination" class="pagination"></div>
      </div>
    </div>
    <!--底部-->
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
    export default {
        name: "contactUs",
        components:{Footer,Header}
    }
</script>

<style scoped>

  .ul-o li a {
    text-align: center;
  }
</style>
