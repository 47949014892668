<template>
  <div class="wrap">
    <!--头部-->
    <Header current="aboutUs"></Header>
    
    <!--内容部分-->
    <div class="pkcont">
      <div class="container-fixed pkconts">
        <div class="o-m">
          <div class="ul-o">
            <ul>
              <li class="li-o sk_clic">
                <router-link to="/aboutUs">关于我们</router-link>
              </li>
              <li class="li-o">
                <router-link to="/contactUs">联系我们</router-link>
              </li>
            </ul>
          </div>
          <div class="ul-t sk_ul">
            <li style="display: block">
              <div class="sk_wo wow fadeInUp delay-02s">
                <p class="sk_about">关于我们</p>
                <p class="about">ABOUT US</p>
                <div class="sk_aboutnr">
                  <p class="wzs">
                    深圳头程国际货运代理有限公司成立于2015年，简称:头程国际，是一家专业从事亚马逊FBA头程运输服务企业，专业代理亚马逊FBA头程运输、FBA空运代理、FBA海运代理，短期仓储等FBA一条龙服务。
                  </p>
                  <p class="wzs">
                    头程国
                    际货运专注“货运互联”，实现信息共享、技术共享、业务能力共享,可以健全运营网络,提供规模化、集约化、网络化，国际化。我司坚持“客户至上、诚信为本、优质服务、开拓创新”的服务理念，热诚为广大客户提供专业、优质的服务。
                  </p>
                  <p class="wzs">
                    头程国际在未来将不断推出形式多样的跨国物流产品和特色服务，增加网络的全球覆盖率，提高头程国际产品的市场占有率，在不断提高服务时效性和稳定性的同时，合理降低服务成本，提高市场竞争力，加强头程国际的品牌建设和宣传，使头程国际成为客户首选和物超所值的优质物流服务品牌。
                  </p>
                </div>
              </div>
              <div class="sk_wo wow fadeInUp delay-02s">
                <p class="sk_about">公司愿景</p>
                <p class="about">COMPANY VISION</p>
                <div class="sk_aboutnrs">
                  <p class="wzsed">
                    <span
                      >一流的中小企业货运服务提供商、运输行业的标杆和领导者；</span
                    >
                  </p>
                  <p class="wzsed">
                    <span
                      >与股东、客户、合作者和员工长期合作共赢的价值伙伴；</span
                    >
                  </p>
                  <p class="wzsed">
                    <span
                      >一家值得信赖、受人尊敬的、在国内具有影响力的类跨境货运公司。</span
                    >
                  </p>
                </div>
              </div>
              <div class="sk_wo sk_woc wow fadeInUp delay-02s">
                <p class="sk_about">公司团队</p>
                <p class="about">COMPANY TEAM</p>
                <div class="sk_tus">
                  <div class="sk_tuwensed">
                    <div class="sk_tuimg">
                      <div class="tu_Img">
                        <img src="@/assets/Image/man.png" alt="" class="gstd" />
                        <div class="zw">
                          <p class="lq">Allen</p>
                          <p class="zjl">COO</p>
                        </div>
                      </div>
                      <div class="jsed">
                        <p class="cs">货拼网创始人，5年物流经验</p>
                        <p class="cs1">
                          为客户提供专业的物流方案，运营方案，达到多方共赢
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="sk_tuwensed">
                    <div class="sk_tuimg">
                      <div class="tu_Img">
                        <img src="@/assets/Image/man.png" alt="" class="gstd" />
                        <div class="zw">
                          <p class="lq">Eric</p>
                          <p class="zjl">CTO</p>
                        </div>
                      </div>
                      <div class="jsed">
                        <p class="cs">
                          7年JAVA开发，3年物流软件开发，精通核心功能编写，对科技物流有一定的见解
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="sk_tuwensed">
                    <div class="sk_tuimg">
                      <div class="tu_Img">
                        <img src="@/assets/Image/man.png" alt="" class="gstd" />
                        <div class="zw">
                          <p class="lq">Jeffrey</p>
                          <p class="zjl">OP&Officer</p>
                        </div>
                      </div>
                      <div class="jsed">
                        <p class="cs">
                          能吃苦耐劳珍惜时间、有自信心、不断进取和善于学习总结的人.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sk_wos wow fadeInUp delay-02s">
                <p class="sk_about">公司环境</p>
                <p class="about">CORPORATE ENVIRONMENT</p>
                <div class="sk_gshj">
                  <a href=""
                    ><img src="@/assets/Image/about_env01.png" alt=""
                  /></a>
                  <a href=""
                    ><img src="@/assets/Image/about_env02.png" alt=""
                  /></a>
                  <a href=""
                    ><img src="@/assets/Image/about_env03.png" alt=""
                  /></a>
                </div>
              </div>
            </li>
          </div>
          <div class="clear"></div>
        </div>
        <div id="pagination" class="pagination"></div>
      </div>
    </div>
    <!--底部-->
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
export default {
  name: 'aboutUs',
  components:{Footer,Header}
}
</script>

<style scoped>
.ul-o li a {
  text-align: center;
}
</style>
