import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import ElementUI from 'element-ui';
import './assets/CSS/bx-cover.css'
import './assets/CSS/zui.css'
import './assets/CSS/index.css'
import './assets/CSS/swiper.css'
import './assets/CSS/animate.css'
import './assets/CSS/info_aos.css'
import './assets/CSS/info_style.css'

import {
  Button, Input,Select,Option,Form,FormItem,Table,TableColumn,Message,Link
} from 'element-ui'
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 }

Message.install = function (Vue, options) {
  Vue.prototype.$message = Message
}
Vue.use(Button)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Message)
Vue.use(Link)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
