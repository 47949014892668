<template>
  <div class="wrap">
    <!--头部-->
    <Header  current="price"></Header>
    <!--内容部分-->
    <div class="pkcont">
      <div class="container-fixed pkconts">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <div class="sk_shaix">
            <div id="main_demo">
              <div class="sk_fwlx">
                <p class="fwlx"><span>服务类型</span></p>
                <el-select
                  class="item"
                  v-model="channelType"
                  placeholder="请选择"
                  disabled
                >
                  <el-option
                    v-for="item in channelTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="sk_jhc">
                <p class="fwlx"><span>交货舱</span></p>
                <el-select
                  class="item"
                  v-model="dispatchPlace"
                  placeholder="请选择"
                  disabled
                >
                  <el-option
                    v-for="item in dispatchPlaceList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <el-form-item class="sk_mdd" prop="fbacode">
                <p class="fwlx"><span>目的地</span></p>
                <el-select
                  class="item"
                  v-model="ruleForm.fbacode"
                  placeholder="请选择目的地"
                  filterable
                  style="width: 188px"
                >
                  <el-option
                    v-for="(item, index) in fbacodeList"
                    :key="index"
                    :label="item.code + '-' + item.zip"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="sk_zhongliang1" prop="kg">
                <p class="fwlx"><span>重量</span></p>
                <el-input v-model="ruleForm.kg" class="kgCbm" placeholder="kg"></el-input>
              </el-form-item>
              <el-form-item class="sk_tiji1" prop="cbm">
                <p class="fwlx"><span>体积</span></p>
                <el-input v-model="ruleForm.cbm" class="kgCbm" placeholder="cbm"></el-input>
              </el-form-item>
            </div>
            <div class="sk_jies">
              <el-button
                type="warning"
                class="yfys"
                @click="findPrice('ruleForm')"
                >运费预算</el-button
              >
            </div>
          </div>
        </el-form>

        <div class="hide-mian">
          <div class="tuiji">
            <p class="sk_tjimg"><span>经济方案 [推荐]</span></p>
            <el-table
              stripe
              :data="shippingPriceData"
              style="width: 100%; line-height: 60px; font-size: 16px"
              :header-cell-style="{
                background: '#2693ec',
                height: '40px',
                lineHeight: '40px',
                color: '#FFF',
                fontSize: '18px'
              }"
            >
              <el-table-column
                prop="channelName"
                label="渠道"
              ></el-table-column>
              <el-table-column
                prop="channelType"
                label="渠道类型"
              >
              <template slot-scope="scope">
                  <span v-if="scope.row.channelType === 'air'">国际空运</span>
                  <span v-if="scope.row.channelType === 'shipping'">国际海运</span>
                  <span v-if="scope.row.channelType === 'express'">国际快递</span>
                </template>
              </el-table-column>
              <el-table-column prop="dutyType" label="是否包税">
                <template slot-scope="scope">
                  <span v-if="scope.row.dutyType === '0'">包税</span>
                  <span v-if="scope.row.dutyType === '1'">不包税</span>
                </template>
              </el-table-column>
              <el-table-column prop="dispatchPlace" label="收货仓"></el-table-column>
              <el-table-column prop="num" label="数量">
                <template slot-scope="scope">
                  <span v-if="scope.row.settleType === '0'">{{scope.row.num}}kg</span>
                  <span v-if="scope.row.settleType === '1'">{{scope.row.num}}cbm</span>
                </template>
              </el-table-column>
              <el-table-column prop="price" label="单价/元"></el-table-column>
              <el-table-column prop="totalMoney" label="总价/元" sortable></el-table-column>
              <el-table-column prop="" label="操作">
                <el-button type="warning">
                  <el-link
                    href="http://erp.huoping.net"
                    target="_blank"
                    style="color: white"
                    :underline="false"
                    >下单</el-link
                  >
                </el-button>
              </el-table-column>
            </el-table>
          </div>
          <div class="tuiji">
            <p class="sk_hangkong"><span>空派包税 [双清包税 接敏感货]</span></p>
            <el-table
              stripe
              :data="airPriceData"
              style="width: 100%; line-height: 60px; font-size: 16px"
              :header-cell-style="{
                background: '#2693ec',
                height: '40px',
                lineHeight: '40px',
                color: '#FFF',
                fontSize: '18px'
              }"
            >
              <el-table-column
                prop="channelName"
                label="渠道"
              ></el-table-column>
              <el-table-column
                prop="channelType"
                label="渠道类型"
              >
              <template slot-scope="scope">
                  <span v-if="scope.row.channelType === 'air'">国际空运</span>
                  <span v-if="scope.row.channelType === 'shipping'">国际海运</span>
                  <span v-if="scope.row.channelType === 'express'">国际快递</span>
                </template>
              </el-table-column>
              <el-table-column prop="dutyType" label="是否包税">
                <template slot-scope="scope">
                  <span v-if="scope.row.dutyType === '0'">包税</span>
                  <span v-if="scope.row.dutyType === '1'">不包税</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="dispatchPlace"
                label="收货仓"
              ></el-table-column>
              <el-table-column prop="num" label="数量">
                <template slot-scope="scope">
                  <span v-if="scope.row.settleType === '0'">{{scope.row.num}}kg</span>
                  <span v-if="scope.row.settleType === '1'">{{scope.row.num}}cbm</span>
                </template>
              </el-table-column>
              <el-table-column prop="price" label="单价/元"></el-table-column>
              <el-table-column
                prop="totalMoney"
                label="总价/元"
                sortable
              ></el-table-column>
              <el-table-column prop="" label="操作">
                <el-button type="warning">
                  <el-link
                    href="http://erp.huoping.net"
                    target="_blank"
                    style="color: white"
                    :underline="false"
                    >下单</el-link
                  >
                </el-button>
              </el-table-column>
            </el-table>
          </div>
          <div class="tuiji">
            <p class="sk_quanqiu"><span>国际快递 [UPS,FEDEX,DHL]</span></p>
            <table>
              <tr class="htit">
                <th>渠道</th>
                <th>收货仓</th>
                <th>船期航班</th>
                <th>时效</th>
                <th>计费方式</th>
                <th>计费重/方</th>
                <th>单价</th>
                <th>总费用</th>
                <th style="text-align: center">附加费</th>
                <th style="text-align: center">下单</th>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--底部-->
    <Footer></Footer>
  </div>
</template>

<script>
import api from '../api/api'
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
export default {
  name: 'Price',
  components: { Footer ,Header},
  data() {
    return {
      channelTypeList: [
        {
          value: '',
          label: '全部方案'
        },
        {
          value: 'express',
          label: '国际快递'
        },
        {
          value: 'air',
          label: '国际空运'
        },
        {
          value: 'shipping',
          label: '国际海运'
        }
      ],
      channelType: '',
      dispatchPlace: '',
      dispatchPlaceList: [
        {
          value: '',
          label: '全国'
        }
      ],
      fbacodeList: [
        // {
        //   value: '',
        //   label: '',
        // }
      ],
      // 正则验证
      rules: {
        fbacode: [{ required: true, message: '请输入目的地', trigger: 'blur' }],
        kg: [{ required: true, message: '请输入重量', trigger: 'blur' }],
        cbm: [{ required: true, message: '请输入体积', trigger: 'blur' }]
      },
      ruleForm: {
        fbacode: '',
        kg: '',
        cbm: ''
      },
      shippingPriceData: [],
      airPriceData: []
    }
  },
  computed:{
    zip(){
      for(var i=0;i<this.fbacodeList.length;i++){
        if(this.fbacodeList[i].code == this.ruleForm.fbacode){
          return this.fbacodeList[i].zip
        }
      }
      return ''
    }
  },
  methods: {
    fbacodeInit() {
      let that = this
      api.findFbacode().then((res) => {
        console.log(res)
        that.fbacodeList = res.data.data
      })
    },
    findPrice(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.findShippingPrice() //海运价格
          this.findAirPrice() //空运价格
        } else {
          return false
        }
      })
    },
    findShippingPrice() {
      let that = this

      api
        .findPrice({
          channelType: 'shipping',
          dispatchPlace: '',
          kg: that.ruleForm.kg,
          cbm: that.ruleForm.cbm,
          fbacode: that.ruleForm.fbacode,
          zip: that.zip
        })
        .then((res) => {
          console.log(res)
          if (res.data.code === 'ok') {
            that.shippingPriceData = res.data.data
            console.log(res)
          } else {
            that.$message.error(res.data.message)
          }
        })
    },
    findAirPrice() {
      let that = this
      api
        .findPrice({
          channelType: 'air',
          dispatchPlace: '',
          kg: that.ruleForm.kg,
          cbm: that.ruleForm.cbm,
          fbacode: that.ruleForm.fbacode,
          zip: that.zip
        })
        .then((res) => {
          console.log(res)
          if (res.data.code === 'ok') {
            that.airPriceData = res.data.data
            console.log(res)
          } else {
            that.$message.error(res.data.message)
          }
        })
    }
  },

  created() {
    this.fbacodeInit()
  }
}
</script>

<style scoped>
.kgCbm {
  width: 140px;
  height: 36px;
  margin-top: -9px;
  margin-left: 10px;
  padding-left: 10px;
  float: left;
}
</style>
