import axios from 'axios'

const instance = axios.create({
    baseURL: 'https://www.huoping.net/api',
    // baseURL: 'http://localhost:8087',
    timeout: 10000
})

export default {
    get:function(url,params){
        return instance.get(url,{params:params})
    },
    post:function(url,params){
        return instance.post(url,params)
    }
}

