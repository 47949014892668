import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import AboutUs from "@/views/AboutUs";
import ContactUs from "@/views/ContactUs";
import Price from "@/views/Price";
import Notice from "@/views/Notice";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "首页"
    }

  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: AboutUs,
    meta: {
      title: "关于我们"
    }
  },
  {
    path: '/contactUs',
    name: 'ContactUs',
    component: ContactUs,
    meta: {
      title: "联系我们"
    }
  },
  {
    path: '/price',
    name: 'Price',
    component: Price,
    meta: {
      title: "查价下单"
    }
  },
  {
    path: '/notice',
    name: 'Notice',
    component: Notice,
    meta: {
      title: "平台公告"
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title + "-货拼网"
  }
  // if ()
  next();
})

export default router
