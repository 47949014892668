<template>
  <div class="sk_headed">
      <div class="container-fixed sk_heads">
        <div class="sk_Head">
          <p>
            <a href="Javascript:;">您好，欢迎访问我们网站！客服QQ:800181585</a>
          </p>
          <div class="sk_LR">
            <div class="yh">
              <img src="@/assets/Image/yh.png" alt="" />
            </div>
            <div class="sk_deng">
              <ul>
                <li><a href="http://old.huoping.net/login"  target="_blank">旧系统</a></li>
                <li><a href="http://erp.huoping.net"  target="_blank">新系统</a></li>
              </ul>
            </div>
          </div>
        </div>

        <div class="lNav">
          <div class="logs">
            <img src="@/assets/Image/log.png" alt="" />
          </div>
          <div class="lnav">
            <ul>
              <li><router-link to="/">首页</router-link></li>
              <li><router-link to="">拼柜列表</router-link></li>
              <li :class="{sk_mr:current==='price'}"><router-link to="/price">查价下单</router-link></li>
              <li :class="{sk_mr:current==='notice'}"><router-link to="/notice">平台公告</router-link></li>
              <li :class="{sk_mr:current==='aboutUs'}">
                <router-link to="/aboutUs">关于我们</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  props:[
    'current'
  ]
}
</script>