<template>
  <div class="wrap">
    <!--Banner-->
    <div class="sk_banner">
      <!--头部-->
      <div class="sk_head">
        <div class="container-fixed sk_heads">
          <div class="sk_Head">
            <p><a href="Javascript:;">您好，欢迎访问我们网站！客服QQ:800181585</a></p>
            <div class="sk_LR">
              <div class="yh">
                <img src="@/assets/Image/yh.png" alt=""/>
              </div>

              <div class="sk_deng">
                <ul>
                  <li><a href="http://old.huoping.net/login" target="_blank">旧系统</a></li>
                  <li><a href="http://erp.huoping.net"  target="_blank">新系统</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--log以及导航-->
      <div class="sk_nav">
        <div class="container-fixed sk_Nav">
          <div class="sk_log">
            <img src="@/assets/Image/log.png" alt=""/>
          </div>
          <div class="Navs">
            <ul>
              <li class="sk_mr">
                <router-link to="/">首页</router-link>
              </li>
              <li>
                <router-link to="">拼柜列表</router-link>
              </li>
              <li>
                <router-link to="/price">查价下单</router-link>
              </li>
             <li>
                <router-link to="/notice">平台公告</router-link>
              </li>
              <li>
                <router-link to="/aboutUs"> 关于我们</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--搜索框-->
      <div class="sk_ss wow fadeInUp delay-02s">
        <div class="container-fixed">
          <div class="sous">
            <div class="h_right">
              <div class="h_s_l">
                <img src="@/assets/Image/kuaid.png" alt=""/>
              </div>
              <div class="dropdowned" id="search">
                <input
                  type="text"
                  class="dropdown-selected"
                  id="search-input"
                  placeholder="请输入目的地(ONT8，92551)"
                />
                <ul id="search-ul"></ul>
              </div>
              <input
                type="button"
                value="搜索"
                class="sk_soued"
                ID="search-btn"
              />
            </div>
          </div>
        </div>
        o
      </div>

      <!--滚动广告-->
      <div class="sk_gg">
        <div class="container-fixed sk_guangao">
          <div class="sk_laba">
            <img src="@/assets/Image/laba.png" alt="" class="laba"/>
          </div>
          <div class="sk_laguang">
            <p class="sk_ggpt"><a href="">平台公告:</a></p>
          </div>
          <div class="swiper-container sk_swipers" id="gonggao">
            <div class="swiper-wrapper">
              <div class="swiper-slide swiper-slideText">
                <a href="">平台公告：宁波点空派收货点已上线！！</a>
              </div>
              <div class="swiper-slide swiper-slideText">
                <a href="">2019年2月平台业绩增涨80%</a>
              </div>
              <div class="swiper-slide swiper-slideText">
                <a href="">公司成功收购品牌域名,价格或超百万美金!</a>
              </div>
            </div>
          </div>
          <div class="movers">
            <p class="mover">
              <a href="Javascript:;" title="更多内容">更多公告 ></a>
            </p>
          </div>
        </div>
      </div>
      <!--说明-->
      <div class="sk_shuo wow fadeInUp delay-03s">
        <div class="zdks">
          <div class="container-fixed sk_shoum">
            <div class="sk_sm">
              <div class="sk_xc">
                <a href="Javascript:;">
                  <img
                    src="@/assets/Image/shoufei.png"
                    alt=""
                    class="shoufei"
                  />
                  <div class="sk_shuoming">
                    <p class="ycx">一次性收费</p>
                    <p class="ycxTit">头程费用透明<br/>信用月结</p>
                  </div>
                </a>
              </div>
              <div class="sk_xc">
                <a href="Javascript:;">
                  <img
                    src="@/assets/Image/shoufei.png"
                    alt=""
                    class="shoufei"
                  />
                  <div class="sk_shuoming">
                    <p class="ycx">专线直达</p>
                    <p class="ycxTit">
                      多家航空船公司合作<br/>拒绝中间商赚差价
                    </p>
                  </div>
                </a>
              </div>
              <div class="sk_xc">
                <a href="Javascript:;">
                  <img
                    src="@/assets/Image/shoufei.png"
                    alt=""
                    class="shoufei"
                  />
                  <div class="sk_shuoming">
                    <p class="ycx">安全快捷</p>
                    <p class="ycxTit">与平安保险合作<br/>没票都送10万元损陪</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sk_lunbo wow fadeInUp delay-02s">
      <div class="container-fixed sk_Lb">
        <div class="lunbo">
          <div class="tejia">
            <a href="">
              <img src="@/assets/Image/tejia.png" alt=""/>
              <div class="TJ">
                <p class="jrtj">今日特价</p>
                <p class="tjText">TODAY'S DEAL</p>
              </div>
            </a>
          </div>
          <div class="sk_LUN">
            <div class="swiper-container swipers" id="tejia">
              <div class="swiper-wrapper">
                <a href="">
                  <div class="swiper-slide swiper-slides">
                    <div class="swiper-slidesed">
                      <div class="Swper Swpers">
                        <div class="Swimg">
                          <a href="">
                            <img src="@/assets/Image/lunchuan.png" alt=""/>
                            <div class="Swingtxt">海运拼箱</div>
                          </a>
                        </div>
                        <div class="Swtext">
                          <p class="sk_stxt">
                            美国&nbsp;&nbsp;&nbsp;FTW1 TX专线
                          </p>
                          <div class="s_wf">
                            <a href="">
                              <p class="sk_chufa">SHENZHEN</p>
                              <img
                                src="@/assets/Image/jiantou.png"
                                alt=""
                                class="jian"
                              />
                              <p class="sk_zhand">DALAX</p>
                            </a>
                          </div>
                          <div class="sk_jia">
                            <a href="">
                              <img
                                src="@/assets/Image/jiage.png"
                                alt=""
                                class="jiage"
                              />
                              <p class="jia">800</p>
                              <p class="sk_xuj">剩余15方</p>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="Swper Swpers">
                        <div class="Swimg">
                          <a href="">
                            <img src="@/assets/Image/lunchuan.png" alt=""/>
                            <div class="Swingtxt">海运拼箱</div>
                          </a>
                        </div>
                        <div class="Swtext">
                          <p class="sk_stxt">深圳-美国ONT8专线</p>
                          <div class="s_wf">
                            <a href="">
                              <p class="sk_chufa">SHENZHEN</p>
                              <img
                                src="@/assets/Image/jiantou.png"
                                alt=""
                                class="jian"
                              />
                              <p class="sk_zhand">长滩</p>
                            </a>
                          </div>
                          <div class="sk_jia">
                            <a href="">
                              <img
                                src="@/assets/Image/jiage.png"
                                alt=""
                                class="jiage"
                              />
                              <p class="jia">500</p>
                              <p class="sk_xuj">剩余22方</p>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="Swper Swpers">
                        <div class="Swimg">
                          <a href="">
                            <img src="@/assets/Image/lunchuan.png" alt=""/>
                            <div class="Swingtxt">海运拼箱</div>
                          </a>
                        </div>
                        <div class="Swtext">
                          <p class="sk_stxt">深圳--ONT/LGB/SMF</p>
                          <div class="s_wf">
                            <a href="">
                              <p class="sk_chufa">SHENZHEN</p>
                              <img
                                src="@/assets/Image/jiantou.png"
                                alt=""
                                class="jian"
                              />
                              <p class="sk_zhand">长滩</p>
                            </a>
                          </div>
                          <div class="sk_jia">
                            <a href="">
                              <img
                                src="@/assets/Image/jiage.png"
                                alt=""
                                class="jiage"
                              />
                              <p class="jia">600</p>
                              <p class="sk_xuj">剩余15方</p>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="Swper Swpers">
                        <div class="Swimg">
                          <a href="">
                            <img src="@/assets/Image/lunchuan.png" alt=""/>
                            <div class="Swingtxt">海运拼箱</div>
                          </a>
                        </div>
                        <div class="Swtext">
                          <p class="sk_stxt">深圳--英国FBA专线</p>
                          <div class="s_wf">
                            <a href="">
                              <p class="sk_chufa">SZ</p>
                              <img
                                src="@/assets/Image/jiantou.png"
                                alt=""
                                class="jian"
                              />
                              <p class="sk_zhand">FELIXSTOWE</p>
                            </a>
                          </div>
                          <div class="sk_jia">
                            <a href="">
                              <img
                                src="@/assets/Image/jiage.png"
                                alt=""
                                class="jiage"
                              />
                              <p class="jia">800</p>
                              <p class="sk_xuj">剩余22方</p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="">
                  <div class="swiper-slide swiper-slides">
                    <div class="swiper-slidesed">
                      <div class="Swper Swpers">
                        <div class="Swimg">
                          <a href="">
                            <img src="@/assets/Image/lunchuan.png" alt=""/>
                            <div class="Swingtxt">海运拼箱</div>
                          </a>
                        </div>
                        <div class="Swtext">
                          <p class="sk_stxt">深圳--日本OSAKA专线</p>
                          <div class="s_wf">
                            <a href="">
                              <p class="sk_chufa">SHENZHEN</p>
                              <img
                                src="@/assets/Image/jiantou.png"
                                alt=""
                                class="jian"
                              />
                              <p class="sk_zhand">OSAKA</p>
                            </a>
                          </div>
                          <div class="sk_jia">
                            <a href="">
                              <img
                                src="@/assets/Image/jiage.png"
                                alt=""
                                class="jiage"
                              />
                              <p class="jia">750</p>
                              <p class="sk_xuj">剩余8方</p>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="Swper Swpers">
                        <div class="Swimg">
                          <a href="">
                            <img src="@/assets/Image/lunchuan.png" alt=""/>
                            <div class="Swingtxt">海运拼箱</div>
                          </a>
                        </div>
                        <div class="Swtext">
                          <p class="sk_stxt">深圳--日本TOKYO专线</p>
                          <div class="s_wf">
                            <a href="">
                              <p class="sk_chufa">SHENZHEN</p>
                              <img
                                src="@/assets/Image/jiantou.png"
                                alt=""
                                class="jian"
                              />
                              <p class="sk_zhand">TOKYO</p>
                            </a>
                          </div>
                          <div class="sk_jia">
                            <a href="">
                              <img
                                src="@/assets/Image/jiage.png"
                                alt=""
                                class="jiage"
                              />
                              <p class="jia">800</p>
                              <p class="sk_xuj">剩余6方</p>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="Swper Swpers">
                        <div class="Swimg">
                          <a href="">
                            <img src="@/assets/Image/lunchuan.png" alt=""/>
                            <div class="Swingtxt">海运拼箱</div>
                          </a>
                        </div>
                        <div class="Swtext">
                          <p class="sk_stxt">加拿大&nbsp;&nbsp;&nbsp;YVR专线</p>
                          <div class="s_wf">
                            <a href="">
                              <p class="sk_chufa">SZ</p>
                              <img
                                src="@/assets/Image/jiantou.png"
                                alt=""
                                class="jian"
                              />
                              <p class="sk_zhand">VANCOUVER</p>
                            </a>
                          </div>
                          <div class="sk_jia">
                            <a href="">
                              <img
                                src="@/assets/Image/jiage.png"
                                alt=""
                                class="jiage"
                              />
                              <p class="jia">600</p>
                              <p class="sk_xuj">剩余15方</p>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="Swper Swpers">
                        <div class="Swimg">
                          <a href="">
                            <img src="@/assets/Image/lunchuan.png" alt=""/>
                            <div class="Swingtxt">海运拼箱</div>
                          </a>
                        </div>
                        <div class="Swtext">
                          <p class="sk_stxt">加拿大&nbsp;&nbsp;&nbsp;YYZ专线</p>
                          <div class="s_wf">
                            <a href="">
                              <p class="sk_chufa">SZ</p>
                              <img
                                src="@/assets/Image/jiantou.png"
                                alt=""
                                class="jian"
                              />
                              <p class="sk_zhand">TORONTO</p>
                            </a>
                          </div>
                          <div class="sk_jia">
                            <a href="">
                              <img
                                src="@/assets/Image/jiage.png"
                                alt=""
                                class="jiage"
                              />
                              <p class="jia">800</p>
                              <p class="sk_xuj">剩余15方</p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="">
                  <div class="swiper-slide swiper-slides">
                    <div class="swiper-slidesed">
                      <div class="Swper Swpers">
                        <div class="Swimg">
                          <a href="">
                            <img src="@/assets/Image/lunchuan.png" alt=""/>
                            <div class="Swingtxt">海运拼箱</div>
                          </a>
                        </div>
                        <div class="Swtext">
                          <p class="sk_stxt">深圳--印度普货专线</p>
                          <div class="s_wf">
                            <a href="">
                              <p class="sk_chufa">SZ</p>
                              <img
                                src="@/assets/Image/jiantou.png"
                                alt=""
                                class="jian"
                              />
                              <p class="sk_zhand">NEW DELHI</p>
                            </a>
                          </div>
                          <div class="sk_jia">
                            <a href="">
                              <img
                                src="@/assets/Image/jiage.png"
                                alt=""
                                class="jiage"
                              />
                              <p class="jia">1700</p>
                              <p class="sk_xuj">剩余12方</p>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="Swper Swpers">
                        <div class="Swimg">
                          <a href="">
                            <img src="@/assets/Image/lunchuan.png" alt=""/>
                            <div class="Swingtxt">海运拼箱</div>
                          </a>
                        </div>
                        <div class="Swtext">
                          <p class="sk_stxt">深圳/义乌--快船包税</p>
                          <div class="s_wf">
                            <a href="">
                              <p class="sk_chufa">SHENZHEN</p>
                              <img
                                src="@/assets/Image/jiantou.png"
                                alt=""
                                class="jian"
                              />
                              <p class="sk_zhand">LA</p>
                            </a>
                          </div>
                          <div class="sk_jia">
                            <a href="">
                              <img
                                src="@/assets/Image/jiage.png"
                                alt=""
                                class="jiage"
                              />
                              <p class="jia">6/KG</p>
                              <p class="sk_xuj">剩3600KG</p>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="Swper Swpers">
                        <div class="Swimg">
                          <a href="">
                            <img src="@/assets/Image/lunchuan.png" alt=""/>
                            <div class="Swingtxt">海运拼箱</div>
                          </a>
                        </div>
                        <div class="Swtext">
                          <p class="sk_stxt">深圳/义乌--美森包税</p>
                          <div class="s_wf">
                            <a href="">
                              <p class="sk_chufa">SHENZHEN</p>
                              <img
                                src="@/assets/Image/jiantou.png"
                                alt=""
                                class="jian"
                              />
                              <p class="sk_zhand">LA</p>
                            </a>
                          </div>
                          <div class="sk_jia">
                            <a href="">
                              <img
                                src="@/assets/Image/jiage.png"
                                alt=""
                                class="jiage"
                              />
                              <p class="jia">8/KG</p>
                              <p class="sk_xuj">剩1200KG</p>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="Swper Swpers">
                        <div class="Swimg">
                          <a href="">
                            <img src="@/assets/Image/lunchuan.png" alt=""/>
                            <div class="Swingtxt">空运拼箱</div>
                          </a>
                        </div>
                        <div class="Swtext">
                          <p class="sk_stxt">深圳/义乌--美国经济线</p>
                          <div class="s_wf">
                            <a href="">
                              <p class="sk_chufa">SHENZHEN</p>
                              <img
                                src="@/assets/Image/jiantou.png"
                                alt=""
                                class="jian"
                              />
                              <p class="sk_zhand">LA</p>
                            </a>
                          </div>
                          <div class="sk_jia">
                            <a href="">
                              <img
                                src="@/assets/Image/jiage.png"
                                alt=""
                                class="jiage"
                              />
                              <p class="jia">19/kg</p>
                              <p class="sk_xuj">剩2000kg</p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <!-- 如果需要分页器 -->
              <div class="swiper-pagination"></div>
              <!-- 如果需要导航按钮 -->
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sk_chuan wow">
      <div class="container-fixed chaun">
        <div class="chuanTIT">
          <P class="cq">船期列表</P>
          <div class="moveres">
            <p class="sk_movers">
              <a href="Javascript:;" title="更多内容">更多 ></a>
            </p>
          </div>
          <div class="cqts">
            <p class="Cti">
              <a href=""
              >所有拼柜都是按时开船，一律按下单完成，才算完成拼柜，下单完成后，将有操作人员与您联系</a
              >
            </p>
          </div>
        </div>
        <div class="danzs">
          <table>
            <tr>
              <th>编号</th>
              <th>出货地</th>
              <th>船期</th>
              <th>国家</th>
              <th>航程</th>
              <th>目的地CODE</th>
              <th>价格</th>
            </tr>
            <tr v-for="item in chuanqiList" :key="item.code">
              <td>{{item.code}}</td>
              <td>{{item.city}}</td>
              <td>{{item.chuanqi}}</td>
              <td>{{item.country}}</td>
              <td>{{item.hangcheng}}</td>
              <td>{{item.fbacode}}</td>
              <td>{{item.price}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

<Footer></Footer>
    
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import '../assets/CSS/bx-cover.css'
import '../assets/CSS/zui.css'
import '../assets/CSS/index.css'
import '../assets/CSS/swiper.css'
import '../assets/CSS/animate.css'

export default {
  components:{Footer},
  data() {
    return {
      chuanqiList: [
        {
          code: '2019-ONT8专线',
          city: '深圳',
          chuanqi: '4截7开',
          country: '美国',
          hangcheng: '22天',
          fbacode: '美国ONT8',
          price: '￥500起'
        },
        {
          code: '2019-FTW1专线',
          city: '深圳',
          chuanqi: '4截2开',
          country: '美国',
          hangcheng: '32天',
          fbacode: '美国FTW1',
          price: '￥750起'
        },
        {
          code: '2019-89快船',
          city: '深圳/义乌',
          chuanqi: '4截2开',
          country: '美国',
          hangcheng: '20天',
          fbacode: '美国邮编89',
          price: '￥5起'
        },
        {
          code: '2019-789空派',
          city: '深圳/义乌',
          chuanqi: '5截7开',
          country: '美国',
          hangcheng: '15天',
          fbacode: '美国邮编789',
          price: '￥18-22'
        }
      ],


    }
  },
  created: async function () {
    // const {data:res} = await api.findFbacode()
    // console.log(res)
  }
}
</script>
<style lang="less" scoped>
</style>
