import request from "@/utils/request"

export default {
  findFbacode: function () {
    return request.get("/manager/api/1.0/sys/param/list", { type: 'sys_fbacode', enable: '1' })
  },

  findPrice(params) {
    return request.get("/portal/api/1.0/price", params)
  }
}
