<template>
  <!--头部-->
  <div>
  <Header  current="notice"></Header>
  <!--内容部分-->
  <div class="main_a">
    <div class="main_box">
      <!--  右边主要内容 -->
      <div class="main_right">
        <div class="Leader">

          <div class="history">
            <ul>
              <li class="his_left">
                <div class="his_main">
                  <h3 aos-delay="500" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">预计2021年4月</h3>
                  <h3 aos-delay="700" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">海外仓</h3>
                  <p aos-delay="900" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">海外仓服务，可以为卖家提供海外仓一件代发业务，卖家可以先把货物存放在我们的海外仓库，一旦有客户下单，我们就会根据订单信息进行配货和发货，这对所有卖家来说都是只有好处没有坏处。</p></div>

                <div class="his_icon aos-init aos-animate" aos-delay="300" aos-easing="ease" aos="zoom-in"></div></li><li class="his_right">
              <div class="his_main">
                <h3 aos-delay="500" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">2020年6月</h3>
                <h3 aos-delay="700" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">突破</h3>
                <p aos-delay="900" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">客户在准备好货物及相关文件之后，之后会派人上门收货。公司对货物进行检验、包装检查、文件检查等操作后，就会订舱，并向国内报关，之后运送到机场或港口，然后安排到日本。到达日本后会安排清关，之后根据客户预约的送货时间送达亚马逊指定的仓库。</p></div>
              <div class="his_icon aos-init aos-animate" aos-delay="300" aos-easing="ease" aos="zoom-in"></div></li><li class="his_left">
              <div class="his_main">
                <h3 aos-delay="500" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">2020年4月</h3>
                <h3 aos-delay="700" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">加拿大运输</h3>
                <p aos-delay="900" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">目前我司加拿大海卡专线，头程选用船司COSCO/EMC，头程海运时间控制在13-15天，同时船司在目的港服务较好，能尽量减少铁路转运带来的延误，到温哥华正常是22-25天；到YOW1的时效，衔接最顺利的一次也做到了开船后30天实现签收。铁路转运延误，通常是以7天为周期，这趟柜子没有安排上铁路，那么延误就是7天，以此类推。</p></div>
              <div class="his_icon aos-init aos-animate" aos-delay="300" aos-easing="ease" aos="zoom-in"></div></li><li class="his_right">
              <div class="his_main">
                <h3 aos-delay="500" aos-easing="ease" aos="fade-up" class="aos-init aos-animate" >2019年6月</h3>
                <h3 aos-delay="700" aos-easing="ease" aos="fade-up" class="aos-init aos-animate" >日本FBA</h3>
                <p aos-delay="900" aos-easing="ease" aos="fade-up" class="aos-init aos-animate" >深圳与宁波集货海运+当地清关不逆算+目的国合作海外仓华人团队+2部免预约车派送，时效15-18天。价格便宜，适合不紧急的补货。这种更多适合的是旺季大批量补货或者有稳定销量的产品。.由于日本与我们地域相近，在品味、节庆、习惯和尺寸方面也跟中国有很多相似之处，中国卖家在日本市场也具有很多天然的优势。。</p></div>
              <div class="his_icon aos-init aos-animate" aos-delay="300" aos-easing="ease" aos="zoom-in"></div></li><li class="his_left">
              <div class="his_main">
                <h3 aos-delay="500" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">2019年3月</h3>
                <h3 aos-delay="700" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">国际化</h3>
                <p aos-delay="900" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">打通海外仓。利于互联网思维，进一步发展打通两地数据，加强头程在运输方面的信息有效性，让多国对接省下更多的时间与快捷。</p></div>
              <div class="his_icon aos-init aos-animate" aos-delay="300" aos-easing="ease" aos="zoom-in"></div></li><li class="his_right">
              <div class="his_main">
                <h3 aos-delay="500" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">2018年6月</h3>
                <h3 aos-delay="700" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">研发<!--3-->
                  <p aos-delay="900" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">技术团队。5年货代经验工程师，快递空运海运货代系统的迭代升级，创新研发，利用前后端分离，实现更高级的货代系统TMS，引入自动化，智能化功能，达到一键生成的效果。</p></h3></div>
              <div class="his_icon aos-init aos-animate" aos-delay="300" aos-easing="ease" aos="zoom-in"></div></li><li class="his_left">
              <div class="his_main">
                <h3 aos-delay="500" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">2017年</h3>
                <h3 aos-delay="700" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">发展
                  <p aos-delay="900" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">美国渠道。我司坚持“客户至上、诚信为本、优质服务、开拓创新”的服务理念，热诚为广大客户提供专业、优质的服务。</p></h3></div>
              <div class="his_icon aos-init aos-animate" aos-delay="300" aos-easing="ease" aos="zoom-in"></div></li><li class="his_right">
              <div class="his_main">
                <h3 aos-delay="500" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">2015年</h3>
                <h3 aos-delay="700" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">立业</h3>
                <p aos-delay="900" aos-easing="ease" aos="fade-up" class="aos-init aos-animate">深圳头程国际货运代理有限公司成立于2015年，简称:头程国际，是一家专业从事亚马逊FBA头程运输服务企业，专业代理亚马逊FBA头程运输、FBA空运代理、FBA海运代理，短期仓储等FBA一条龙服务。</p></div>
              <div class="his_icon aos-init aos-animate" aos-delay="300" aos-easing="ease" aos="zoom-in"></div></li>

            </ul></div>
<!--          <script type="text/javascript">-->
<!--            AOS.init({-->
<!--              easing: 'ease-out-back',-->
<!--              duration: 1000-->
<!--            });-->
<!--          </script>-->

        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>

  <!--底部-->
  <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
    export default {
        name: "Notice",
        components:{Footer,Header}
    }
</script>

<style scoped>

</style>
